interface Locale {
  [key: string]: {
    en_US: string;
    ar_SA: string;
    tr_TR: string;
    zh_CN?: string;
  };
}

const locales: Locale = {
  语言: {
    en_US: 'English',
    ar_SA: 'لغة',
    tr_TR: 'dil',
  },
  '请求地址异常，请检查': {
    en_US: 'The request address is abnormal, please check',
    ar_SA: 'عنوان الطلب غير طبيعي، يرجى التحقق',
    tr_TR: 'İstek adresi anormal, lütfen kontrol edin',
  },
  '请求网络异常，请稍后': {
    en_US: 'Request network exception, please wait.',
    ar_SA: 'طلب استثناء الشبكة، يرجى الانتظار.',
    tr_TR: 'Ağ istisnası isteyin, lütfen bekleyin.',
  },
  请求失败: {
    en_US: 'Request failed',
    ar_SA: 'فشل الطلب',
    tr_TR: 'İstek başarısız oldu',
  },
  公会: {
    en_US: 'Angency',
    ar_SA: 'يتبع CY',
    tr_TR: 'Acente',
  },
  搜索: {
    en_US: 'Search',
    ar_SA: 'يبحث',
    tr_TR: 'aramak',
  },
  '搜索公会名称/公会长昵称/ID': {
    en_US: 'Search for agency name/Agent nickname/ID',
    ar_SA: 'ابحث عن اسم الوكالة/اسم الوكيل/ ID للوكالة',
    tr_TR: 'Ajans adını/takma adını/kimliğini arayın',
  },
  '搜索姓名/ID': {
    en_US: 'Search for name / ID',
    ar_SA: 'يبحث عن الاسم / الهوية',
    tr_TR: 'Isim / Kimlik aramak',
  },
  加入: {
    en_US: 'Join',
    ar_SA: 'إنضمام',
    tr_TR: 'Katılmak',
  },
  提示: {
    en_US: 'Tips',
    ar_SA: 'إشعار',
    tr_TR: 'İPUÇLARI',
  },
  '确定加入该公会嘛？': {
    en_US: 'Confirm to apply to join this agency?',
    ar_SA: 'هل أنت متؤكد من الانضمام إلى الوكالة هذه؟',
    tr_TR: 'Bu ajansa katılmak için başvuruyu onaylıyor musunuz?',
  },
  成员申请: {
    en_US: 'Member Application',
    ar_SA: 'طلبات الأعضاء',
    tr_TR: 'Üye Başvurusu',
  },
  待处理: {
    en_US: 'Pending',
    ar_SA: 'قيد المعالجة',
    tr_TR: 'İslemde',
  },
  已同意: {
    en_US: 'Agreed',
    ar_SA: 'موافق',
    tr_TR: 'İlgi',
  },
  已拒绝: {
    en_US: 'Rejected',
    ar_SA: 'مرفوض',
    tr_TR: 'Reddedildi',
  },
  拒绝: {
    en_US: 'Refuse',
    ar_SA: 'يرفض',
    tr_TR: 'Reddetmek',
  },
  同意: {
    en_US: 'Agree',
    ar_SA: 'موافقة',
    tr_TR: 'İlgili',
  },
  会员: {
    en_US: 'Member',
    ar_SA: 'عضو',
    tr_TR: 'Üye',
  },
  邀请: {
    en_US: 'Invite',
    ar_SA: 'مشاركة',
    tr_TR: 'Ilaç',
  },
  移除公会管理员: {
    en_US: 'Remove agency admin',
    ar_SA: 'إزالة مشرف الوكالة',
    tr_TR: 'Ajans yöneticisini kaldır',
  },
  设置公会管理员: {
    en_US: 'Set up agency admin',
    ar_SA: 'تعيين مشرف الوكالة',
    tr_TR: 'Ajans yöneticisini ayarla',
  },
  移出公会: {
    en_US: 'Remove from the agency',
    ar_SA: 'إزالته من الوكالة',
    tr_TR: 'Ajanstan kaldır',
  },
  没有更多了: {
    en_US: 'No more',
    ar_SA: 'لا يوجد المزيد',
    tr_TR: 'Daha fazla yok',
  },
  '申请加入失败，请稍后重试~': {
    en_US: 'Application to join failed, please try again later',
    ar_SA: 'فشل تقديم الطلب للانضمام، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'Katılmak için başvuru yapılamadı, lütfen daha sonra tekrar deneyin',
  },
  '申请加入成功，请耐心等待公会通过~': {
    en_US: 'The application to join is successful. Please wait patiently for the guild to approve it.',
    ar_SA: 'تم قبول طلب الانضمام بنجاح، يرجى الانتظار بصبر حتى تتم الموافقة على النقابة.',
    tr_TR: 'Katılım başvurunuz başarılı oldu, lütfen loncanın onaylanmasını sabırla bekleyin.',
  },
  '同意申请失败，请稍后重试~': {
    en_US: 'Agree to apply failed, please try again later',
    ar_SA: 'فشل الموافقة على الطلب للانضمام، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'İlgiye cevaplanmadı, lütfen daha sonra tekrar deneyin',
  },
  '同意成功': {
    en_US: 'Agree successfully',
    ar_SA: 'تم الموافقة بنجاح',
    tr_TR: 'İlgili',
  },
  '拒绝成功': {
    en_US: 'Reject successfully',
    ar_SA: 'تم الرفض بنجاح',
    tr_TR: 'Reddedildi',
  },
  '确认同意{{name}}': {
    en_US: 'Confirm to agree {{name}}',
    ar_SA: 'هل ترغب في الموافقة على {{name}}',
    tr_TR: 'İlgiyi onaylamak istiyor musunuz',
  },
  '确认同意{{name}} (ID:{{id}})加入公会吗？': {
    en_US: 'Confirm to agree {{name}} (ID:{{id}}) to join the agency?',
    ar_SA: 'هل ترغب في الموافقة على {{name}} (ID:{{id}}) للانضمام للنقابة؟',
    tr_TR: '{{name}} (ID:{{id}}) katılımını onaylamak istiyor musunuz?',
  },
  '确认拒绝{{name}} (ID:{{id}})加入公会吗？': {
    en_US: 'Confirm to reject {{name}} (ID:{{id}}) to join the agency?',
    ar_SA: 'هل ترغب في رفض {{name}} (ID:{{id}}) للانضمام للنقابة؟',
    tr_TR: '{{name}} (ID:{{id}}) katılımını reddetmek istiyor musunuz?',
  },
  '拒绝申请失败，请稍后重试~': {
    en_US: 'Reject application failed, please try again later',
    ar_SA: 'فشل رفض الطلب للانضمام، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'Katılmak için başvuru reddedildi, lütfen daha sonra tekrar deneyin',
  },
  '操作ID:{{id}}': {
    en_US: 'Executor ID: {{id}}',
    ar_SA: 'ID العملية: {{id}}',
    tr_TR: 'Operasyon ID: {{id}}',
  },
  '公会长': {
    en_US: 'Owner',
    ar_SA: 'وكيل',
    tr_TR: 'Ajans Sahibi',
  },
  公会管理员: {
    en_US: ' Admin',
    ar_SA: 'ادمن ',
    tr_TR: 'Yönetici',
  },
  公会成员: {
    en_US: 'Member',
    ar_SA: 'أعضاء ',
    tr_TR: 'Üyeler',
  },
  '确认将{{name}} (ID:{{id}})从公会中移除吗？': {
    en_US: 'Confirm to remove {{name}} (ID:{{id}}) from the agency?',
    ar_SA: 'هل ترغب في حذف {{name}} (ID:{{id}}) من النقابة؟',
    tr_TR: '{{name}} (ID:{{id}}) nın katılımını kaldırmak istiyor musunuz?',
  },
  移除成功: {
    en_US: 'Remove successfully',
    ar_SA: 'تم الحذف بنجاح',
    tr_TR: 'Kaldırıldı',
  },
  '移除失败，请稍后重试~': {
    en_US: 'Remove failed, please try again later',
    ar_SA: 'فشل الحذف، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'Kaldırılamadı, lütfen daha sonra tekrar deneyin',
  },
  '确认设置{{name}} (ID:{{id}})为管理员吗？': {
    en_US: 'Confirm to set {{name}} (ID:{{id}}) as admin?',
    ar_SA: 'هل تريد تأكيد تعيين {{name}} (ID:{{id}}) كمسؤول؟',
    tr_TR: '{{name}}(ID:{{id}}) adlı kişinin ajans yöneticisi olarak ayarlanması onaylansın mı?',
  },
  '确认从管理员中移除 {{name}}(ID:{{id}}) 吗？': {
    en_US: 'Confirm to remove {{name}} (ID:{{id}}) from the admin?',
    ar_SA: 'متؤكد في إزالة {{0}}(ID:{{id}}) من مشرفين الوكالة؟',
    tr_TR: "{{0}}(ID:{{1}})'nin yöneticiden kaldırılması onaylansın mı?",
  },
  设置公会管理员成功: {
    en_US: 'Set up agency admin successfully',
    ar_SA: 'تم تعيين المشرف',
    tr_TR: 'Ajans yöneticisini başarıyla ayarladınız',
  },
  移除公会管理员成功: {
    en_US: 'Removed the admin successfully',
    ar_SA: 'تمت إزلة مشرف الوكالة',
    tr_TR: 'Yönetici başarıyla kaldırıld',
  },
  '设置公会管理员失败，请稍后重试': {
    en_US: 'Failed to set up the agency admin, please try again later.',
    ar_SA: 'فشل التعيين،يرجى تجربة مرة اخرى',
    tr_TR: 'Ajans yöneticisi ayarlanamadı.Lütfen daha sonra tekrar deneyin.',
  },
  '移除管理员失败，请稍后重试': {
    en_US: 'Failed to remove agency admin please try again later',
    ar_SA: 'فشلت الإزالة، يرجى تجربة مرة اخرى',
    tr_TR: 'Ajans yöneticisi kaldırılamadı, lütfen daha sonra tekrar deneyin',
  },
  成员数量: {
    en_US: 'Members',
    ar_SA: 'عدد الاعضاء',
    tr_TR: 'Kullanıcılar',
  },
  邀请成员: {
    en_US: 'Invite Member',
    ar_SA: 'دعوة عضو',
    tr_TR: 'Kullanıcı Ekle',
  },
  数据中心: {
    en_US: 'Data Center',
    ar_SA: 'مركز البيانات',
    tr_TR: 'Veri Merkezi',
  },
  会员收到硬币: {
    en_US: 'Member Received Coins',
    ar_SA: 'تم استلام القيمة',
    tr_TR: 'Yayıncı Coins Sıralaması',
  },
  复制成功: {
    en_US: 'Copy successfully',
    ar_SA: 'تم النسخ بنجاح',
    tr_TR: 'Başarıyla kopyalandı',
  },
  公会不存在: {
    en_US: 'Angency not exist',
    ar_SA: 'الجمعية غير موجودة',
    tr_TR: 'Takım mevcut degil',
  },
  已邀请: {
    en_US: 'Invited',
    ar_SA: 'تم الدعوة',
    tr_TR: 'Davet edildi',
  },
  邀请成功: {
    en_US: 'Invite successfully',
    ar_SA: 'تم الدعوة بنجاح',
    tr_TR: 'Davet edildi',
  },
  '邀请失败，请稍后重试': {
    en_US: 'Invite failed, please try again later',
    ar_SA: 'فشل الدعوة، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'Davet edilemedi, lütfen daha sonra tekrar deneyin',
  },
  充值账户: {
    "en_US": "Recharge Account",
    "ar_SA": "حساب الشحن",
    "tr_TR": "şarj hesabı"
  },
  充值: {
    "en_US": "Recharge",
    "ar_SA": "الشحن",
    "tr_TR": "şarj etmek"
  },
  请输入充值的用户ID: {
    "en_US": "Please Enter User ID",
    "ar_SA": "أدخل ايدي",
    "tr_TR": "Lütfen şarj kullanıcı kimliğini girin"
  },
  该ID不存在: {
    "en_US": "This ID doesn’t exist.",
    "ar_SA": "هذا الايدي ليس موجودا",
    "tr_TR": "Kimlik mevcut değil"
  },
  不能为空: {
    "en_US": "Cannot be empty",
    "ar_SA": "لا يمكن أن تكون فارغة",
    "tr_TR": "Boş olamaz"
  },
  查询: {
    "en_US": "Verify",
    "ar_SA": "التحقيق",
    "tr_TR": "Sorgula"
  },
  热门: {
    "en_US": "Hot",
    "ar_SA": "شائع",
    "tr_TR": "popüler"
  },
  所有: {
    "en_US": "All",
    "ar_SA": "جميع",
    "tr_TR": "Tümü"
  },
  '阅读并同意App的服务条款、退货政策和隐私政策': {
    "en_US": "Read and agree to App's Terms of Service, Return Policy and Privacy Policy",
    "ar_SA": "اقرأ ووافق على شروط الخدمة سياسة الإرجاع وسياسة الخاصة بشاتي الخصوصية",
    "tr_TR": "App'nin Hizmet Şartları, İade Politikası ve Gizlilik Politikasını okuyun ve kabul edin"
  },
  更换其他ID: {
    "en_US": "Recharge other ID",
    "ar_SA": "تغيير الايدي",
    "tr_TR": "Başka bir kimliği değiştir"
  },
  请先选择需要充值的ID: {
    "en_US": "Please select the ID that needs to be recharged first",
    "ar_SA": "الرجاء إدخال معرف المستخدم",
    "tr_TR": "Lütfen önce şarj edilmesi gereken kimliği seçin"
  },
  帮助: {
    "en_US": "Help",
    "ar_SA": "ساعد",
    "tr_TR": "yardım"
  },
  请选择国家: {
    "en_US": "Please select a country",
    "ar_SA": "رجاء قم بإختيار دوله",
    "tr_TR": "Lütfen bir ülke seçin"
  },
  请选择支付方式: {
    "en_US": "Please select mode of payment",
    "ar_SA": "الرجاء تحديد طريقة الدفع",
    "tr_TR": "Lütfen ödeme şeklini seçin"
  },
  请选择充值金额: {
    "en_US": "Please enter the recharge amount",
    "ar_SA": "الرجاء إدخال مبلغ إعادة الشحن",
    "tr_TR": "Lütfen şarj miktarını seçin"
  },
  请选择同意条款: {
    "en_US": "Please agree to the terms",
    "ar_SA": "الرجاء الموافقة على الشروط",
    "tr_TR": "Lütfen şartları kabul etmeyi seçin"
  },
  成功: {
    "en_US": "The operation was successful",
    "ar_SA": "العملية كانت ناجحه",
    "tr_TR": "İşlem Başarılı Oldu"
  },
  登录: {
    "en_US": "Log in",
    "ar_SA": "تسجيل الدخول",
    "tr_TR": "Giriş Yap"
  },
  用户名: {
    "en_US": "UserName",
    "ar_SA": "اسم المستخدم",
    "tr_TR": "Kullanıcı Adı"
  },
  用户名不能为空: {
    "en_US": "User name cannot be empty",
    "ar_SA": "اسم المستخدم لا يمكن ان يكون فارغ",
    "tr_TR": "Kullanıcı adı bos olamaz"
  },
  密码: {
    "en_US": "Password",
    "ar_SA": "كلمة المرور",
    "tr_TR": "Parola"
  },
  密码不能为空: {
    "en_US": "Password cannot be empty",
    "ar_SA": "كلمة المرور لا يمكن ان تكون فارغة",
    "tr_TR": "Parola bos olamaz"
  },
  硬币转移: {
    "en_US": "Coin Transfer",
    "ar_SA": "نقل العملات",
    "tr_TR": "Para Transferi"
  },
  可用金币: {
    "en_US": "Available coins",
    "ar_SA": "العملات المتوفرة",
    "tr_TR": "Kullanılabilir Para"
  },
  分配: {
    "en_US": "Distribute",
    "ar_SA": "توزيع",
    "tr_TR": "Döküm"
  },
  输入您要转移的用户ID: {
    "en_US": "Input the user ID you want to transfer",
    "ar_SA": "أدخل معرف المستخدم الذي تريد نقله",
    "tr_TR": "Aktarmak istediğiniz kullanıcı kimliğini girin"
  },
  请输入用户ID: {
    en_US: "Please input user ID",
    ar_SA: "الرجاء إدخال معرف المستخدم",
    tr_TR: "Lütfen kullanıcı kimliğini girin"
  },
  请输入您要转账的金额: {
    en_US: 'Input the amount you want to transfer',
    ar_SA: 'أدخل المبلغ الذي تريد تحويله',
    tr_TR: 'Transfer etmek istediğiniz tutarı girin'
  },
  '一次最多 9999999': {
    en_US: 'Max 9999999 in 1 time',
    ar_SA: 'الحد الأقصى 9999999 في المرة الواحدة',
    tr_TR: '1 seferde en fazla 9999999'
  },
  '确认转账<b>{{num}}</b>个金币至ID:<b>{{id}}</b>': {
    en_US: 'Confirm to transfer <b>{{num}}</b> coins to ID:<b>{{id}}</b>',
    ar_SA: 'تأكيد توزيع <b>{{num}}</b> كونزات إلى المعرف:<b>{{id}}</b>',
    tr_TR: "<b>{{num}}</b> ID'ye Altın Aktarılmasını Onaylayın:<b>{{id}}</b>"
  },
  '该用户不属于您所在的国家': {
    en_US: 'The user is not from your country',
    ar_SA: 'المستخدم ليس من بلدك',
    tr_TR: 'Kullanıcı sizin ülkenizden değil'
  },
  转账成功: {
    en_US: 'Transfer successfully',
    ar_SA: 'تم التوزيع بنجاح',
    tr_TR: 'Başarıyla Aktarıldı'
  },
  '亲爱的App用户：': {
    en_US: 'Dear App users:',
    ar_SA: ':عزيزي مستخدمي',
    tr_TR: 'Sevgili App Kullanıcıları:'
  },
  '最近，我们发现有些用户恶意退款，我们无法容忍这种欺诈性的非法金币购买行为。': {
    en_US: 'Recently, we have seen some users maliciously refunding money, and we cannot tolerate such fraudulent and illegal gold purchases.',
    ar_SA: 'لاحظنا مؤخرًا أن بعض المستخدمين قاموا برد الأموال بشكل ضار، لا يمكننا أن نتسامح مع هذا الشراء الاحتيالي وغير القانوني للعملة الذهبية',
    tr_TR: 'Son zamanlarda bazı kullanıcıların kötü niyetli olarak Coins&Yakut iadesi yaptığını görüyoruz ve bu tür hileli ve yasa dışı coins, yakut alımlarına tolerans gösteremeyiz.',
  },
  txt2: {
    zh_CN: '任何ID充值后恶意退款都将被系统检测到，并将会被视为恶意欺诈用户，系统将对该ID进行处罚。处罚包括:充值限制、金币使用限制、房间进入限制、永久ID禁止、设备永久禁止等。',
    en_US: 'Any ID malicious refunds after recharge will be detected by the system and will be regarded as a malicious fraudulent user, and the system will penalize this ID.Penalties include but are not limited to: recharge restrictions, currency usage restrictions, room entry restrictions, permanent ID bans, permanent bans on devices, etc.',
    ar_SA: 'سيتم الكشف عن أي استرداد ضار بعد إعادة شحن المعرف بواسطة النظام. سيعتبر هذا الأيدي مستخدما احتياليا ضارا، وسيقوم النظام بمعاقبة هذا الأيدي. تشمل العقوبات على سبيل المثال لا الحصر: الحد من إعادة الشحن، والحد من استخدام العملة، والحد من دخول إلى الغرفة، وحظر أيدي أبدا، وحظر الجهاز أبدا، وما إلى ذلك',
    tr_TR: `Yükleme sonrasında yapılan herhangi bir geri iade sistem tarafından tespit edilecek ve kötü niyetli dolandırıcı bir kullanıcı olarak kabul edilecek ve sistem bu kişiyi veya kişileri cezalandıracaktır. Cezalar şunları içerir ancak bunlarla sınırlı değildir;<br/>
    - Yeniden yükleme kısıtlamaları<br/>
    - Coins&Yakut kullanım kısıtlamaları<br/>
    - Odaya giriş kısıtlamaları<br/>
    - Kalıcı ID yasakları<br/>
    - Cihazlara kalıcı yasaklar vb.<br/>`,
  },
  '请珍惜您的账号，不要将账号交给他人，远离欺诈性购买，共同维护正常秩序，同时，我们也欢迎用户的举报。': {
    en_US: 'Please value your account, do not give your account to others, stay away from fraudulent purchases. At the same time, we also welcome users to report!',
    ar_SA: 'يرجى الاعتزاز بحسابك، وعدم إعطاء حسابك للآخرين، والابتعاد عن عمليات الشراء الاحتيالية، والحفاظ بشكل مشترك على أمر فله ! في الوقت نفسه، نرحب أيضا بالمستخدمين للإبلاغ',
    tr_TR: 'Lütfen hesabınızın kıymetini bilin, hesabınızı başkalarına vermeyin, hileli alışverişlerden uzak durun. Aynı zamanda kullanıcıları da bu tür kullanıcıları tesbit halinde yönetime bilgi vermeye davet eder İyi Eğlenceler Dileriz.',
  }
};

export default locales;
