import React from 'react';
import App from '@/pages/App'
// @ts-ignore
import RouterGuard from '@/components/RouterGuard'

const Guild = React.lazy(() => import('@/pages/Guild'))
const GuildList = React.lazy(() => import('@/pages/Guild/list'))
const MemberApply = React.lazy(() => import('@/pages/Guild/apply'))
const Member = React.lazy(() => import('@/pages/Guild/member'))
const Invite = React.lazy(() => import('@/pages/Guild/invite'))
// const Demo = React.lazy(() => import('@/pages/Guild/demo'))
const Recharge = React.lazy(() => import('@/pages/Recharge'))
const AgentLogin = React.lazy(() => import('@/pages/Agent/login'))
const AgentCoin = React.lazy(() => import('@/pages/Agent/coin'))
const PayResult = React.lazy(() => import('@/pages/Pay/result'))
const PayNotice = React.lazy(() => import('@/pages/Pay/notice'))

export interface Route {
  path: string
  name: string
  element: JSX.Element
  state?: any
  meta?: any
  [key: string]: any
}

export interface RoutesMap {
  [key: string]: string
}

const routes: Route[] = [
  {
    path: '/',
    name: 'App',
    title: 'Hoby',
    element: (<App />),
  },
  {
    path: '/guild',
    name: 'Guild',
    title: '公会主页',
    element: (<RouterGuard><Guild /></RouterGuard>),
    // element: (<Guild />),
  },
  {
    path: '/guild/list',
    name: 'GuildList',
    title: '公会列表',
    element: (<GuildList />),
  },
  {
    path: '/guild/apply',
    name: 'GuildApply',
    title: '申请加入公会列表',
    element: (<MemberApply />),
  },
  {
    path: '/guild/member',
    name: 'GuildMember',
    title: '成员列表',
    element: (<Member />),
  },
  {
    path: '/guild/invite',
    name: 'GuildInvite',
    title: '邀请粉丝',
    element: (<Invite />),
  },
  {
    path: '/recharge',
    name: 'Recharge',
    title: '充值',
    meta: {
      noAuthLogin: 0, // 不需要登录
    },
    element: (<Recharge />),
  },
  {
    path: '/agent/login',
    name: 'AgentLogin',
    title: '代理登录',
    meta: {
      noAuthLogin: 0, // 不需要登录
    },
    element: (<AgentLogin />),
  },
  {
    path: '/agent/coin',
    name: 'AgentCoin',
    title: '代理商-金币',
    meta: {},
    element: (<AgentCoin />),
  },
  {
    path: '/pay/result',
    name: 'PayResult',
    title: '支付结果',
    meta: {
      noAuthLogin: 0, // 不需要登录
    },
    element: (<PayResult />),
  },
  {
    path: '/pay/notice',
    name: 'PayNotice',
    title: '支付通知',
    meta: {
      noAuthLogin: 0, // 不需要登录
    },
    element: (<PayNotice />),
  },
]

// 用来做路由导航时路径名和路径的映射
export const routesMap: RoutesMap = routes.reduce(
  (acc: RoutesMap, cur: Route) => {
    const { path, name } = cur
    acc[name] = path
    return acc
  },
  {},
)

export default routes
