import user from "./modules/user";
import guild from "./modules/guild";
import common from "./modules/common";
import agent from "./modules/agent";
import pay from "./modules/pay";

export default {
  user,
  guild,
  common,
  agent,
  pay
}