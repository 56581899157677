import http from '../http'

export default {
  // 获取国家列表
  getCountryList(data: object) {
    return http.post('/withoutsign/country', data);
  },
  // 根据ID查询用户
  getUserById(data: object) {
    return http.post('/withoutsign/payermax/user/verify', data);
  },
  login(data: object) {
    return http.post('/withoutsign/user/loginweb', data);
  },
  // 查询用户信息
  getUsreInfo(data: object) {
    return http.post('/web/user/info', data)
  }
}
