import http from '../http'

export default {
  // 获取支付方式
  getPayMethods(data: object) {
    return http.post('/withoutsign/payment/methods', data);
  },
  // 创建订单
  createOrder(data: object) {
    return http.post('/withoutsign/payment/createOrder', data);
  },
  // 查询支付订单
  queryOrder(data: object) {
    return http.post('/withoutsign/payment/queryOrder', data);
  },
  getUsreInfo(data: object, option: object = {}) {
    return http.post('/withoutsign/payment/user', data, option);
  },
}
