import React, { createContext, useState, useContext, useEffect } from 'react';
import apis from '@/apis';
import { utils } from '@/utils'
import { useUserInfoStore } from '@/stores'
import router from '@/router'
import type { Route } from '@/router/routes'

interface UserContextValue {
  userInfo: any;
  loading: boolean;
}

interface Props {
  children: React.ReactNode;
}

const { to } = utils
const { user: userApi } = apis
// 创建一个用户数据上下文
const UserContext = createContext<UserContextValue>({
  userInfo: null,
  loading: true
});

// 用于获取用户数据的自定义钩子
export const useUserInfo = () => {
  return useContext(UserContext);
};


// 用户上下文提供者组件
// @ts-ignore
const UserProvider = ({ children }: Props) => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const userInfoStore = useUserInfoStore();
  const currentPath = window.location.pathname;
  const routerItem: Route = router.routes.find((item: any) => item.path === currentPath) as unknown as Route ?? { name: '', element: null, path: currentPath };

  useEffect(() => {
    const loadUserData = async () => {
      const [err, res] = await to(userApi.getMyAccountInfo({}))
      if (err) {
        console.error('获取用户数据失败', err)
        return
      }
  
      const { res: resData } = res
      
      userInfoStore.setUserInfo(resData)
      setUserInfo(resData);
      setLoading(false);
    };

    if (routerItem && routerItem.meta && routerItem.meta.noAuthLogin === 0) {
      setLoading(false);
    } else {
      loadUserData();
    }
  }, []);

  return (
    <UserContext.Provider value={{ userInfo, loading }}>
      { children }
    </UserContext.Provider>
  );
};

export default UserProvider